class ImageGallery {
  /**
   * Create new image gallery
   * @param {HTMLElement} container Gallery element
   */
  constructor (container) {
    this.container = container

    this.figure = container.querySelector('.js-image-gallery-figure')
    this.image = container.querySelector('.js-image-gallery-image')
    this.caption = container.querySelector('.js-image-gallery-caption')
    this.buttons = container.querySelectorAll('.js-image-gallery-button')

    for (const button of this.buttons) {
      button.addEventListener('click', () => {
        this.setImage(button.dataset.src, button.dataset.caption)
        this.setActiveButton(button)
      })
    }
  }

  /**
   * Load and set image
   * @param {string} src Image source
   * @param {string} caption Image caption
   */
  setImage (src, caption) {
    const image = new Image()

    this.figure.scrollIntoView({ behavior: 'smooth', block: 'start' })

    const timeout = setTimeout(() => {
      this.container.classList.add('is-loading')
    }, 300)

    image.addEventListener('load', () => {
      clearTimeout(timeout)
      this.image.src = image.src
      this.caption.textContent = caption
      this.container.classList.remove('is-loading')
    })

    image.src = src
  }

  /**
   * Set active state to active button
   * @param {HTMLElement} activeButton Active button element
   */
  setActiveButton (activeButton) {
    for (const button of this.buttons) {
      button.classList.remove('is-active')
    }

    activeButton.classList.add('is-active')
  }
}

const containers = document.querySelectorAll('.js-image-gallery')

for (const container of containers) {
  // eslint-disable-next-line no-new
  new ImageGallery(container)
}
