import { createFocusTrap } from 'focus-trap'

class SidebarNav {
  /**
   * Create new sidebar nav
   * @param {HTMLElement} navContainer Navigation element
   * @param {HTMLElement} openButton Button to open the navigation
   * @param {HTMLElement} closeButton Button to close the navigation
   */
  constructor (navContainer, openButton, closeButton) {
    this.navContainer = navContainer
    this.openButton = openButton
    this.closeButton = closeButton

    this.focusTrap = createFocusTrap(navContainer)

    this.openButton.addEventListener('click', () => this.open())
    this.closeButton.addEventListener('click', () => this.close())
  }

  open () {
    this.navContainer.classList.add('is-open')
    document.body.style.overflowY = 'hidden'
    setTimeout(() => this.focusTrap.activate(), 400)
  }

  close () {
    this.navContainer.classList.remove('is-open')
    this.focusTrap.deactivate()
    document.body.style.overflowY = ''
  }
}

const navContainer = document.querySelector('.js-sidebar-nav')
const openButton = document.querySelector('.js-sidebar-nav-open')
const closeButton = document.querySelector('.js-sidebar-nav-close')

if (navContainer && openButton && closeButton) {
  // eslint-disable-next-line no-new
  new SidebarNav(navContainer, openButton, closeButton)
}
